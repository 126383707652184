define("discourse/plugins/retort/discourse/services/retort", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Retort extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    createRetort(_ref, retort) {
      let {
        id
      } = _ref;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "PUT",
        data: {
          retort
        }
      });
    }
    withdrawRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "DELETE",
        data: {
          retort
        }
      });
    }
    removeRetort(_ref3, retort) {
      let {
        id
      } = _ref3;
      return (0, _ajax.ajax)(`/retorts/${id}/all.json`, {
        type: "DELETE",
        data: {
          retort
        }
      });
    }
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split("|");
      return categories.map(cat => parseInt(cat, 10)).filter(Boolean);
    }
    disableShowForTopic(topic) {
      if (!topic) {
        return true;
      }
      const categoryId = topic.get("category.id");
      const disabledCategories = this.disabledCategories();
      return categoryId && disabledCategories.includes(categoryId);
    }
    openPicker(post) {
      const retortAnchor = document.querySelector(`
          article[data-post-id="${post.id}"] .post-controls .retort`);
      if (retortAnchor) {
        retortAnchor.classList.add("emoji-picker-anchor");
      }
      this.set("picker.isActive", true);
      this.set("picker.post", post);
      // eslint-disable-next-line no-unused-vars
      this.set("picker.onEmojiPickerClose", event => {
        const currentRetortAnchor = document.querySelector(".emoji-picker-anchor.retort");
        if (currentRetortAnchor) {
          currentRetortAnchor.classList.remove("emoji-picker-anchor");
        }
        this.set("picker.isActive", false);
      });
    }
    setPicker(picker) {
      this.set("picker", picker);
      this.set("picker.emojiSelected", emoji => this.createRetort(picker.post, emoji).then(data => {
        picker.set("isActive", false);
        if (data.id !== picker.post.id) {
          // eslint-disable-next-line no-console
          console.error("Retort post id mismatch");
        } else {
          picker.post.set("retorts", data.retorts);
          picker.post.set("my_retorts", data.my_retorts);
          this.appEvents.trigger("post-stream:refresh", {
            id: data.id
          });
        }
      }).catch(_ajaxError.popupAjaxError));
    }
  }
  _exports.default = Retort;
});