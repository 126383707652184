define("discourse/plugins/retort/discourse/widgets/post-retort-container", ["discourse/lib/text", "discourse/widgets/widget"], function (_text, _widget) {
  "use strict";

  (0, _widget.createWidget)("post-retort-container", {
    tagName: "div.post-retort-container",
    services: ['retort'],
    buildKey: attrs => `post-retort-container-${attrs.post.id}`,
    html(attrs) {
      const {
        post
      } = attrs;
      if (this.retort.disableShowForTopic(post.topic)) {
        return;
      }
      if (!post.retorts) {
        return;
      }
      const retorts = post.retorts.map(item => {
        item.emojiUrl = (0, _text.emojiUrlFor)(item.emoji);
        return item;
      }).filter(_ref => {
        let {
          emojiUrl
        } = _ref;
        return emojiUrl;
      }).sort((a, b) => a.emoji.localeCompare(b.emoji));
      const retort_widgets = retorts.map(_ref2 => {
        let {
          emoji,
          emojiUrl,
          usernames
        } = _ref2;
        let displayUsernames = usernames;
        // check if hide_ignored_retorts is enabled
        if (this.currentUser?.custom_fields?.hide_ignored_retorts) {
          const ignoredUsers = new Set(this.currentUser.ignored_users);
          displayUsernames = usernames.filter(username => {
            return !ignoredUsers.has(username);
          });
        }
        if (displayUsernames.length > 0) {
          return this.attach("retort-toggle", {
            emoji,
            emojiUrl,
            post,
            usernames: displayUsernames
          });
        } else {
          return null;
        }
      });
      return retort_widgets;
    }
  });
});